import { RemixBrowser } from '@remix-run/react'
import { startTransition } from 'react'
import { hydrateRoot } from 'react-dom/client'

// trying to fix the case on Android when `load` event has already been fired (Android wifi)
// https://whatwebcando.today/articles/handling-service-worker-updates/
async function startServiceWorker() {
	if ('serviceWorker' in navigator) {
		// Use the window load event to keep the page load performant, wait for the page to load
		// window.addEventListener('load', async () => {

		// register the service worker
		let registration = await navigator.serviceWorker.register('/sw.js')

		// ensure the case when the updatefound event was missed is also handled
		// by re-invoking the prompt when there's a waiting Service Worker
		if (registration.waiting) {
			invokeServiceWorkerUpdateFlow(registration)
		}

		// detect Service Worker update available and wait for it to become installed
		registration.addEventListener('updatefound', () => {
			if (registration.installing) {
				// wait until the new Service Worker is actually installed (ready to take over)
				registration.installing.addEventListener('statechange', () => {
					if (registration.waiting) {
						// if there's an existing controller (previous Service Worker), show the prompt
						if (navigator.serviceWorker.controller) {
							invokeServiceWorkerUpdateFlow(registration)
						} else {
							// otherwise it's the first install, nothing to do
							console.log('Service Worker initialized for the first time')
						}
					}
				})
			}
		})

		let refreshing = false

		// detect controller change and refresh the page
		navigator.serviceWorker.addEventListener('controllerchange', () => {
			if (!refreshing) {
				window.location.reload()
				refreshing = true
			}
		})

		// navigator.serviceWorker
		//     .register('/sw.js')
		//     //       .then(() =>{
		//     //   console.log('sw after register');
		//     //       return navigator.serviceWorker.ready
		//     // })
		//     .then(registration => {
		//         console.log('sw ready registration updatefound start');
		//         // if (navigator.serviceWorker.controller) {
		//         //   navigator.serviceWorker.controller.postMessage({
		//         //     type: 'SYNC_REMIX_MANIFEST',
		//         //     manifest: window.__remixManifest,
		//         //   });
		//         // } else {
		//         //   navigator.serviceWorker.addEventListener('controllerchange', () => {
		//         //     navigator.serviceWorker.controller?.postMessage({
		//         //       type: 'SYNC_REMIX_MANIFEST',
		//         //       manifest: window.__remixManifest,
		//         //     });
		//         //   });
		//         // }
		//
		//         registration.addEventListener('updatefound', event => {
		//             console.log('sw updateFound start');
		//             let newSW = registration.installing;
		//             newSW?.addEventListener('statechange', event => {
		//                 if (newSW?.state === 'installed') {
		//                     // New service worker is installed, but waiting activation
		//                     if (
		//                         confirm(
		//                             'A new version is available, reload to update.',
		//                         )
		//                     ) {
		//                         console.log('update SW + reload window');
		//                         registration.update();
		//                         window.location.reload();
		//                     }
		//                 }
		//             });
		//         });
		//     })
		//     .catch(error => {
		//         console.error('Service worker registration failed', error);
		//     });
		// });
	} else {
		console.log('serviceWorker NOT in navigator')
	}
}

function invokeServiceWorkerUpdateFlow(
	registration: ServiceWorkerRegistration,
) {
	if (confirm('A new version is available, reload to update')) {
		if (registration.waiting) {
			registration.waiting.postMessage('SKIP_WAITING')
		}
	}
}

// ------------ epic-stack  ------------------------

// if (ENV.MODE === 'production' && ENV.SENTRY_DSN) {
// 	void import('./utils/monitoring.client.tsx').then(({ init }) => init())
// }

startTransition(() => {
	hydrateRoot(document, <RemixBrowser />)
})

window.setTimeout(startServiceWorker, 5_000)
